export const DATA_COLLECTION_STATUS = {
  Pending: 0,
  Skipped: 1,
  Error: 2,
  Completed: 3,
};

export const DATA_COLLECTION_PROCESS_STATUS = {
  Pending: 'Pending',
  Skipped: 'Skipped',
  Begun: 'Begun',
  Errored: 'Errored',
  Completed: 'Completed',
  TimedOut: 'TimedOut',
  NotEnabled: 'NotEnabled',
};

export const DATA_COLLECTION_PROCESS = {
  ManuelIdVerify: 'ManualIDVerification',
  Scantek: 'Scantek',
  Equifax: 'Equifax',
  Illion: 'Illion',
  VerificationExchange: 'VerificationExchange',
};

export const DATA_COLLECTION_EVENT = {
  Skip: 'Skip',
  Begin: 'Begin',
  Error: 'Error',
  Complete: 'Complete',
};

export const DATA_COLLECTION_VERIFICATION_STEP_COMPLETE_DETAIL =
  'Data collection has already been completed';
