import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorPage from 'containers/ErrorPage';
import Spinner from 'components/Spinner/Spinner';
import { withRouter } from 'react-router';
import UIActions from 'actions/UIActions';
import { primaryApplicantHasSignedPrivacyPolicy } from 'selectors/clientSelectors';
import { getApplicationHasSignedPrivacyPolicy } from 'selectors/applicationSelectors';
import {
  PRIVACY_POLICY_PATH,
  LOGIN_CAPTURE_PATH,
} from 'shared/constants/paths';
import * as UISelectors from 'selectors/UISelectors';
import { featureFlags } from 'lib/rollout';
import LocalStorageProxy from 'lib/localStorageProxy';

export class PageWrapper extends Component {
  static propTypes = {
    fullPageSpinnerLoading: PropTypes.bool,
    pageError: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.node,
    clearPageError: PropTypes.func,
    primaryApplicantHasSignedPrivacyPolicy: PropTypes.booleasn,
    applicationHasSignedPrivacyPolicy: PropTypes.booleasn,
    snippers: PropTypes.array,
  };

  componentDidUpdate(prevProps) {
    // Clear page error on error page leave
    const {
      pageError,
      location,
      clearPageError,
      primaryApplicantHasSignedPrivacyPolicy,
      applicationHasSignedPrivacyPolicy,
      history,
      fetchingUserInfo,
      snippers,
    } = this.props;
    const isPrivacyPolicyEnabled = featureFlags.oneTouchPrivacy.isEnabled();
    const redirectToPrivacy =
      isPrivacyPolicyEnabled &&
      !LocalStorageProxy.loginAsLoanApplicationId &&
      !fetchingUserInfo &&
      !(
        applicationHasSignedPrivacyPolicy ||
        primaryApplicantHasSignedPrivacyPolicy
      );
    const isNotPrivacyPath = history.location.pathname !== PRIVACY_POLICY_PATH;
    const isNotLoginPath = history.location.pathname !== LOGIN_CAPTURE_PATH;
    const isNotLogout =
      snippers && snippers.find((spinner) => spinner !== 'LOGOUT');

    if (
      isNotLoginPath &&
      isNotPrivacyPath &&
      isPrivacyPolicyEnabled &&
      !LocalStorageProxy.loginAsLoanApplicationId &&
      redirectToPrivacy &&
      isNotLogout
    ) {
      history.push(PRIVACY_POLICY_PATH);
    }

    if (pageError && location !== prevProps.location) {
      clearPageError();
    }
  }

  render() {
    const { fullPageSpinnerLoading, pageError, children } = this.props;
    if (pageError) {
      return <ErrorPage error={pageError} />;
    }
    if (fullPageSpinnerLoading) {
      return <Spinner loading />;
    }
    return children;
  }
}

const mapStateToProps = (state) => {
  const { fullPageSpinnerLoading, pageError, snippers } = state.UISettings;
  return {
    fullPageSpinnerLoading,
    pageError,
    snippers,
    primaryApplicantHasSignedPrivacyPolicy: primaryApplicantHasSignedPrivacyPolicy(
      state,
    ),
    applicationHasSignedPrivacyPolicy: getApplicationHasSignedPrivacyPolicy(
      state,
    ),
    fetchingUserInfo: UISelectors.fetchingUserInfo(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPageError: UIActions.clearPageError,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageWrapper),
);
