import UIActions from 'actions/UIActions';
import {
  GET_DATA_COLLECTION_STATUS_AND_EVENTS,
  GET_VERIFICATION_EXCHANGE,
  POLL_DATA_COLLECTION_RETRY_STATUS_AND_EVENTS,
  POLL_DATA_COLLECTION_STATUS_AND_EVENTS,
  POST_VERIFICATION_EXCHANGE,
} from 'actions/dataCollectionActionTypes';
import dataCollectionActions from 'actions/dataCollectionActions';
import { delay } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as UISelectors from 'selectors/UISelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import {
  getDataCollectionStatusAndEvents,
  getVerificationExchangeApi,
  postVerificationExchangeApi,
} from 'services/dataCollectionApi';
import {
  CONTEXT_PAGE_PATH,
  DATA_COLLECTION_CREDIT_CHECK_PATH,
} from 'shared/constants/paths';
import { readLoanApplication } from './loanApplicationSagas';

export const STATUS_CHECK_MAX_ATTEMPTS = 100;

function* startRetryPollingDataCollectionStatus(action) {
  let attemptCount = 0;
  while (true) {
    try {
      yield call(fetchDataCollectionStatusAndEventsSaga, action);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      yield put(UIActions.setPageError({ status: 500 }));
      break;
    }

    const isDataCollectionPending = yield select(
      dataCollectionSelectors.isDataCollectionPending,
    );
    const isBankStatementsPending = yield select(
      dataCollectionSelectors.isBankStatementsPending,
    );
    if (!isDataCollectionPending && !isBankStatementsPending) {
      break;
    }

    attemptCount++;
    if (attemptCount >= STATUS_CHECK_MAX_ATTEMPTS) {
      break;
    }
    yield delay(2000);
  }

  yield delay(1500);
  yield put(
    UIActions.goToPathWithAnimation({
      path: CONTEXT_PAGE_PATH,
    }),
  );
  yield put(UIActions.popSpinner('LOADING'));
}

function* startPollingDataCollectionStatus(action) {
  let attemptCount = 0;
  while (true) {
    try {
      yield call(fetchDataCollectionStatusAndEventsSaga, action);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      yield put(UIActions.setPageError({ status: 500 }));
      break;
    }

    const isDataCollectionPending = yield select(
      dataCollectionSelectors.isDataCollectionPending,
    );
    const isBankStatementsPending = yield select(
      dataCollectionSelectors.isBankStatementsPending,
    );
    if (!isDataCollectionPending && !isBankStatementsPending) {
      break;
    }

    attemptCount++;
    if (attemptCount >= STATUS_CHECK_MAX_ATTEMPTS) {
      break;
    }
    yield delay(2000);
  }

  const applicationIsRead = yield select(UISelectors.applicationIsRead);
  if (applicationIsRead) {
    /**
     * There are 2 scenarios

       1. Data Collection flow when user signin using email / password
       2. Data Collection flow when user is resuming an already logged-in session.
       
       Application with correct expenses are loaded correctly for scenario 1.
       In scenario 2, application is loaded before Data Collection flow.
       Therefore the expenses updated during Data-Collection flow are not displayed in Dashboard.

       The solution is to force refetch loan application after data-collection process.
     */
    yield put(UIActions.resetApplicationIsRead());
    yield call(readLoanApplication);
  }

  const isAutomaticIdVerificationSuccess = yield select(
    dataCollectionSelectors.isAutomaticIdVerificationSuccess,
  );
  const hasCreditCheckFailed = yield select(
    dataCollectionSelectors.hasCreditCheckFailed,
  );
  const primaryClient = yield select(clientSelectors.primaryApplicant);
  if (
    isAutomaticIdVerificationSuccess &&
    hasCreditCheckFailed &&
    !primaryClient.hasCreditCheckRetried
  ) {
    yield put(
      UIActions.goToPathWithAnimation({
        path: DATA_COLLECTION_CREDIT_CHECK_PATH,
      }),
    );
  } else {
    // Whether it is success or failure, we land on application.
    // Delay of 1.5s for the loading animation to finish.
    yield delay(1500);
    yield put(
      UIActions.goToPathWithAnimation({
        path: CONTEXT_PAGE_PATH,
      }),
    );
    yield put(UIActions.popSpinner('LOADING'));
  }
}

export function* fetchDataCollectionStatusAndEventsSaga({
  payload: { applicationId, clientId },
}) {
  let response;
  try {
    response = yield call(
      getDataCollectionStatusAndEvents,
      applicationId,
      clientId,
    );
  } catch (error) {
    yield put(UIActions.setPageError({ status: 500, error }));
    return;
  }
  yield put(dataCollectionActions.setDataCollectionStatusAndEvents(response));
}

function* fetchVerificationExhange({ payload: { applicationId } }) {
  let response;
  yield put(UIActions.pushSpinner('getVerificationExchange'));
  try {
    response = yield call(getVerificationExchangeApi, applicationId);
    yield put(UIActions.popSpinner('getVerificationExchange'));
  } catch (error) {
    yield put(UIActions.setPageError({ status: 500, error }));
    return;
  }
  yield put(dataCollectionActions.setVerificationExchange(response.data));
}

function* postVerificationExhange({ payload: { applicationId, status } }) {
  let results;
  yield put(UIActions.pushSpinner('postVerificationExhange'));
  try {
    results = yield call(postVerificationExchangeApi, {
      applicationId,
      status,
    });
    yield put(UIActions.popSpinner('postVerificationExhange'));
  } catch (error) {
    yield put(UIActions.setPageError({ status: 500, error }));
    return;
  }
  yield put(dataCollectionActions.setVerificationExchange(results.data));
}

export default function* dataCollectionSagas() {
  yield all([
    takeLatest(
      POLL_DATA_COLLECTION_STATUS_AND_EVENTS,
      startPollingDataCollectionStatus,
    ),
    takeLatest(
      GET_DATA_COLLECTION_STATUS_AND_EVENTS,
      fetchDataCollectionStatusAndEventsSaga,
    ),
    takeLatest(
      POLL_DATA_COLLECTION_RETRY_STATUS_AND_EVENTS,
      startRetryPollingDataCollectionStatus,
    ),
    takeLatest(GET_VERIFICATION_EXCHANGE, fetchVerificationExhange),
    takeLatest(POST_VERIFICATION_EXCHANGE, postVerificationExhange),
  ]);
}
