import { createSelector } from 'reselect';
import _ from 'lodash';
import { securityTypes } from 'shared/constants/myCRMTypes/securities';
import {
  structuresNotFiltered,
  sumOfStructureLoanAmount,
} from 'selectors/structureSelectors';
import {
  primaryApplicant,
  primaryApplicantsPartner,
  primaryApplicantContactId,
} from 'selectors/clientSelectors';
import { formatPercentage } from 'lib/intlFormatters';
import { lvrCalculator } from 'shared/lib/utils';
import { featureFlags } from 'lib/rollout';
import { API_FIXED_RESULT } from 'shared/constants/interestType';
import { TRANSACTION_CONSTRUCTIONS } from 'shared/constants/myCRMTypes/property';
import locale from 'config/locale';
import {
  PROFILE_CATEGORIES,
  PROFILE_SECTIONS,
} from 'constants/profileSections';
import {
  isProfileCategoryVisible,
  isProfileSectionVisible,
} from './UISelectors';
import { featureOn } from 'lib/abTestHelper';
import { LOAN_AMOUNT } from 'config/abTest';

export const rootState = (state) => state;

export const entities = (state) => state.application.entities;
export const workingApplication = (state) => state.application.working;
export const getApplicationHasSignedPrivacyPolicy = (state) =>
  state.application.working.hasSignedPrivacyPolicy;
export const workingApplicationMetadata = (state) =>
  state.application.working ? state.application.working.metadata : undefined;
export const goalLoanApplication = (state) => state.goalApplication;

export const isSharedApplication = createSelector(
  workingApplication,
  (application) => !!application.isShared,
);

export const getApplicants = createSelector(
  workingApplication,
  (application) => application.applicants,
);

export const isBrokerCustomerCare = createSelector(
  primaryApplicantContactId,
  workingApplication,
  (selectedContactId, application) => {
    if (
      !application ||
      !application.applicants ||
      // eslint-disable-next-line unicorn/explicit-length-check
      !application.applicants.length ||
      !selectedContactId
    ) {
      return true;
    }

    const findBroker = application.applicants.find(
      (a) => a.contactId === selectedContactId,
    );

    return findBroker ? findBroker.isBrokerCustomerCare : true;
  },
);

export const getPrimaryBrokerId = createSelector(
  primaryApplicantContactId,
  workingApplication,
  (selectedContactId, application) =>
    application.applicants.find((a) => a.contactId === selectedContactId)
      .brokerId,
);

export const getApplicantClientIds = createSelector(
  workingApplication,
  (selectedApplication) =>
    selectedApplication.applicants
      .filter((a) => !!a.clientId)
      .map((a) => a.clientId),
);

const useSecurityFutureValue = (security) => {
  return (
    featureFlags.constructionLoanApps.isEnabled() &&
    TRANSACTION_CONSTRUCTIONS.includes(security.transactionId)
  );
};
export const sumOfSecurities = createSelector(
  workingApplication,
  (selectedApplication) => {
    return _.sumBy(selectedApplication.securities, (security) => {
      const { futureValue, value } = security;
      return (useSecurityFutureValue(security) && futureValue) || value;
    });
  },
);

export const securityReports = createSelector(
  workingApplication,
  (selectedApplication) =>
    selectedApplication.securities.map((security) => {
      return {
        name: securityTypes[security.type],
        value:
          (useSecurityFutureValue(security) && security.futureValue) ||
          security.value ||
          0,
        icon: 'sl-custom-dollar-currency-3',
        isOpenByDefault: true,
        readOnly: true,
        nameInfoLabel: security.address,
      };
    }),
);

// TODO: This is the last instance of numbrero format percentage being used
// we need to change this to intl and remove numbrero. Numbrero is deprecated!
export const securityReportMetaData = createSelector(
  sumOfStructureLoanAmount,
  sumOfSecurities,
  securityReports,
  (loanAmount, sum, selectedSecurities) => {
    return [
      {
        label: 'Securities',
        value: sum,
        displayOnly: true,
        items: [
          ...selectedSecurities,
          {
            name: 'Total LVR',
            value: lvrCalculator(loanAmount, sum) || 0,
            formatValue: formatPercentage,
            icon: 'sl-custom-dollar-currency-3',
            isOpenByDefault: true,
            readOnly: true,
          },
        ],
      },
    ];
  },
);

export const structureReportMetadata = createSelector(
  isSharedApplication,
  structuresNotFiltered,
  sumOfStructureLoanAmount,
  (isShared, selectedStructures, totalLoanAmount) => {
    if (isShared) {
      return [];
    }
    return [
      {
        label: 'Proposed Lending',
        value: totalLoanAmount,
        displayOnly: true,
        items: selectedStructures.map((s) => {
          if (!s.productDetail) {
            return {};
          }
          const {
            lender,
            interestRateTypeInitial,
            initialPeriod,
            repaymentTypeInitial,
          } = s.productDetail;
          const nameInfoLabel =
            API_FIXED_RESULT.includes(interestRateTypeInitial) &&
            initialPeriod > 12
              ? `${interestRateTypeInitial} - ${initialPeriod / 12}Y`
              : interestRateTypeInitial;
          return {
            name: (lender && lender.name) || 'No lender selected',
            icon: 'sl-custom-dollar-bag',
            value: s.loanAmount,
            isOpenByDefault: true,
            readOnly: true,
            valueInfoLabel: repaymentTypeInitial,
            nameInfoLabel,
          };
        }),
      },
    ];
  },
);

export const getIsLocked = createSelector(
  workingApplication,
  (application) => !!application.isLocked,
);

export const getApplicationId = createSelector(
  workingApplication,
  (application) => application.id,
);

export const creditHistoryDetails = createSelector(
  workingApplication,
  (application) =>
    _.pick(application, [
      'bankrupt',
      'bankruptDetail',
      'behindInPayments',
      'behindInPaymentsDetail',
      'director',
      'directorDetail',
      'conduct',
    ]),
);

export const isQuestionAnswered = (yesNoQuestion, detailQuestion) =>
  yesNoQuestion !== undefined && !(yesNoQuestion && !detailQuestion);

export const getCreditHistoryQuestionsLeft = createSelector(
  workingApplication,
  (application) => {
    const {
      behindInPayments,
      behindInPaymentsDetail,
      director,
      directorDetail,
      bankrupt,
      bankruptDetail,
      conduct,
    } = application;

    const questions = [
      isQuestionAnswered(bankrupt, bankruptDetail),
      isQuestionAnswered(behindInPayments, behindInPaymentsDetail),
      isQuestionAnswered(director, locale.isNZ ? true : directorDetail),
    ];

    if (featureFlags.goalSetterFactFindMerge.isEnabled()) {
      questions.push(conduct !== undefined);
    }

    return questions.filter((v) => !v).length;
  },
);

export const clientForeseesChanges = createSelector(
  workingApplicationMetadata,
  (a) =>
    _.memoize((clientId) => {
      return a && a.foreseesChanges ? a.foreseesChanges[clientId] : undefined;
    }),
);

export const foreseeableChanges = createSelector(
  workingApplication,
  (application) => application.foreseeableChanges,
);

export const clientForeseeableChangeDetails = createSelector(
  foreseeableChanges,
  (a) =>
    _.memoize((clientId) => {
      if (!a) {
        return '';
      }
      const change = a.find((c) => c.clientId === clientId);
      return change && change.details;
    }),
);

export const askPartnerForeseesChange = createSelector(
  primaryApplicantsPartner,
  (partner) => partner && partner.isCoapplicant,
);

export const getForeseeableChangeQuestionsLeft = createSelector(
  primaryApplicant,
  askPartnerForeseesChange,
  primaryApplicantsPartner,
  clientForeseesChanges,
  clientForeseeableChangeDetails,
  (primary, askPartner, partner, forseesChanges, changeDetails) => {
    if (!primary) {
      return;
    }
    const primaryAnswered = isQuestionAnswered(
      forseesChanges(primary.id),
      changeDetails(primary.id),
    );
    if (askPartner) {
      const partnerAnswered = isQuestionAnswered(
        forseesChanges(partner.id),
        changeDetails(partner.id),
      );
      return [primaryAnswered, partnerAnswered].filter((v) => !v).length;
    }
    return primaryAnswered ? 0 : 1;
  },
);

export const applicationStatus = createSelector(
  workingApplication,
  (working) => working.applicationStatus || 1,
);

const getVisibleSections = (
  rootState,
  visibleCategories,
  isSharedApplication,
) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const visibleSectionsArr = visibleCategories.map((category) => {
    const sections = [];
    switch (category) {
      case PROFILE_CATEGORIES.GOALS: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.GOAL)) {
          sections.push('goal');
        }
        break;
      }
      case PROFILE_CATEGORIES.APPLICANTS: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.APPLICANT)) {
          sections.push('applicant');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.FAMILY)) {
          sections.push('family');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.COAPPLICANTS)) {
          sections.push('coapplicants');
        }
        break;
      }
      case PROFILE_CATEGORIES.PROPERTIES: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.RESIDENCE)) {
          sections.push('residence');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.TO_PURCHASE)) {
          sections.push('to-purchase');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.PROPERTIES)) {
          sections.push('properties');
        }
        break;
      }
      case PROFILE_CATEGORIES.FINANCIAL_POSITION: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.ASSETS)) {
          sections.push('assets');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.LIABILITIES)) {
          sections.push('liabilities');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.INCOMES)) {
          sections.push('incomes');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.EXPENSES)) {
          sections.push('expenses');
        }
        break;
      }
      case PROFILE_CATEGORIES.HISTORY: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.ADDRESSES)) {
          sections.push('addresses');
        }
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.EMPLOYMENTS)) {
          sections.push('employments');
        }
        if (
          isProfileSectionVisible(rootState)(
            PROFILE_SECTIONS.FORESEEABLE_CHANGES,
          ) &&
          !featureFlags.disableMilliForeseeableChanges.isEnabled() &&
          !featureFlags.goalSetterFactFindMerge.isEnabled() &&
          !locale.isNZ
        ) {
          sections.push('foreseeable-changes');
        }
        if (
          isProfileSectionVisible(rootState)(PROFILE_SECTIONS.CREDIT_HISTORY)
        ) {
          sections.push('credit-history');
        }
        break;
      }
      case PROFILE_CATEGORIES.LOAN_AMOUNT_APPLY: {
        if (locale.isAU && (!isSharedApplication || featureOn(LOAN_AMOUNT))) {
          sections.push('loan-amount');
        }
        break;
      }
      case PROFILE_CATEGORIES.SUPPORTING_INFORMATION: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.DOCUMENTS)) {
          sections.push('documents');
        }
        break;
      }
      case PROFILE_CATEGORIES.PREFERENCES: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.LENDERS)) {
          sections.push('current-bank');
        }
        if (
          isProfileSectionVisible(rootState)(PROFILE_SECTIONS.LOAN_FEATURES)
        ) {
          sections.push('loan-options');
        }
        break;
      }
      case PROFILE_CATEGORIES.LOOKING_AHEAD: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.FUTURE_PLANS)) {
          sections.push('long-term-goals');
        }
        break;
      }
      case PROFILE_CATEGORIES.SERVICES: {
        if (isProfileSectionVisible(rootState)(PROFILE_SECTIONS.SERVICES)) {
          sections.push('services');
        }
        break;
      }
    }
    return sections;
  });

  return visibleSectionsArr.flat();
};

export const unsavedSlugs = createSelector(
  workingApplicationMetadata,
  rootState,
  isSharedApplication,
  (metadata, rootState, isSharedApplication) => {
    const savedSections = [];
    if (metadata?.saved) {
      const temp = Object.keys(metadata.saved).reduce((acc, key) => {
        if (metadata?.saved && metadata?.saved[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
      savedSections.push(...temp);
    }

    const goalSetterFactFindMergeEnabled = featureFlags.goalSetterFactFindMerge.isEnabled();

    const visibleCategories = Object.values(
      PROFILE_CATEGORIES,
    ).filter((category) =>
      isProfileCategoryVisible(rootState)(
        category,
        goalSetterFactFindMergeEnabled,
      ),
    );

    const visibleSections = getVisibleSections(
      rootState,
      visibleCategories,
      isSharedApplication,
    );

    return visibleSections.filter(
      (section) => !savedSections.includes(section),
    );
  },
);

export const hasOldInformation = createSelector(
  workingApplication,
  (application) => application.isOldData,
);
