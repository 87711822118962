import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape } from 'react-intl';
import * as myPropTypes from 'types/customPropTypes';

import { hideBodyOverflow, absolutePopup } from 'lib/utils/browserUtils';
import { isNumber } from 'shared/lib/numbrero';

import Counter from 'components/Counter/Counter';
import styles from './PopupItem.css';
import PopupStatus from './PopupStatus.js';

const isAbsolutePopup = absolutePopup();

export const popupProps = {
  showStatus: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.object),
  clearErrors: PropTypes.func,
  parentPath: PropTypes.string,
  match: PropTypes.object.isRequired,
  name: myPropTypes.stringOrFunction,
  value: myPropTypes.stringOrNumber,
  formatValue: PropTypes.func,
  body: PropTypes.func,
  description: PropTypes.func,
  intl: intlShape.isRequired,
  waitForAsyncRequest: PropTypes.bool,
  closeOnOverlay: PropTypes.bool,
  theme: PropTypes.string,
  actionableError: PropTypes.object,
  onActionableErrorConfirm: PropTypes.func,
  onActionableErrorCancel: PropTypes.func,
  history: PropTypes.object,
  resetFormPopupStatus: PropTypes.func,
};

class Popup extends Component {
  static propTypes = popupProps;

  static defaultProps = {
    requestIsProcessing: false,
    showStatus: true,
    errors: [],
    waitForAsyncRequest: true,
  };

  static styles = styles;

  constructor() {
    super();
    this.state = {
      refreshKey: 1,
      processing: false,
      processed: false,
    };
    hideBodyOverflow(true);
    this.onProcessed = this.closePopup;
    this.timeout = null;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    setTimeout(
      this.popupHeader.scrollIntoView.bind(this.popupHeader),
      100,
    ); /* avoid Illegal invocation call */
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    const { requestIsProcessing, formPopUpStatus } = this.props;
    if (
      nextProps.errors.length > 0 ||
      nextProps.actionableError ||
      (formPopUpStatus &&
        !nextProps.formPopUpStatus.processing &&
        !nextProps.formPopUpStatus.processed)
    ) {
      this.setState({
        processing: false,
        processed: false,
      });
      return;
    }

    if (
      ((requestIsProcessing || this.state.processing) &&
        !nextProps.requestIsProcessing) ||
      (formPopUpStatus && nextProps.formPopUpStatus.processed)
    ) {
      this.setProcessed();
    }

    if (formPopUpStatus && nextProps.formPopUpStatus.processing) {
      this.setProcessing();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    document.removeEventListener('keydown', this.onKeyDown);
    hideBodyOverflow(false);
  }

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.closePopup();
    }
  };

  setProcessed() {
    this.setState({
      processing: false,
      processed: true,
    });

    this.timeout = setTimeout(() => {
      this.executeOnProcessed();
    }, 1000);
  }

  setProcessing() {
    this.setState({
      processing: true,
      processed: false,
    });
  }

  executeOnProcessed = () => {
    this.timeout = null;
    if (this.props.errors.length === 0) {
      this.onProcessed();
    }
  };

  onClickOverlay = ({ target, currentTarget }) => {
    if (!this.props.closeOnOverlay) {
      return;
    }

    if (target === currentTarget) {
      this.closePopup();
    }
  };

  closePopup = () => {
    const { parentPath, errors, clearErrors } = this.props;
    if (this.state.processing) {
      return;
    }
    if (this.props.resetFormPopupStatus) {
      this.props.resetFormPopupStatus();
    }
    this.setState({
      processing: false,
      processed: false,
    });
    if (errors.length > 0 && clearErrors) {
      clearErrors();
    }

    Promise.resolve(typeof this.onClose === 'function' && this.onClose()).then(
      () => {
        if (parentPath) {
          this.props.history.push(parentPath);
        } else {
          console.warn(
            'Hey you probably want to set parentPath on this popup.',
          );
          this.props.history.goBack();
        }
      },
    );
  };

  title() {
    const { name, intl } = this.props;
    return typeof name === 'function' ? name(intl) : name;
  }

  value() {
    const { value } = this.props;
    if (value === undefined) {
      return;
    }
    return value;
  }

  formatValue = (value) => {
    const { formatValue, intl } = this.props;
    return formatValue ? formatValue(intl)(value) : value;
  };

  renderErrors() {
    return this.props.errors.toString();
  }

  renderBody() {
    const { body: PopUpBody, intl, match, waitForAsyncRequest } = this.props;
    const onSubmit = () => {
      if (waitForAsyncRequest) {
        this.setProcessing();
      } else {
        this.setProcessed();
      }
      this.onProcessed = this.closePopup;
    };
    // TODO: rationalise this - so that its known what types a body rendering function accepts
    return (
      PopUpBody && (
        <PopUpBody
          match={match}
          style={Popup.styles}
          onSubmit={onSubmit}
          intl={intl}
        />
      )
    );
  }

  renderDescription(style) {
    const { description } = this.props;
    return description && description(style);
  }

  onActionableErrorConfirm() {
    this.props.onActionableErrorConfirm();
    this.closePopup();
  }

  hideErrorOnForm = () => true;

  render() {
    const {
      errors,
      showStatus,
      theme,
      actionableError,
      onActionableErrorCancel,
    } = this.props;
    const { processed, processing } = this.state;
    const errored = errors.length > 0 || !!actionableError;
    const updating = processed || processing || errored;
    const overlayStyle = classNames(styles.overlay, {
      [styles.absolute]: isAbsolutePopup,
    });

    const rootStyle = classNames(styles.root, {
      [styles.updating]: processing,
      [styles.updated]: processed && !errored,
      [styles.errored]: errored,
      [styles.noScroll]: this.noScroll,
      [styles[theme]]: !!theme,
    });

    let status;
    if (errored) {
      status = actionableError ? 'erroredActionable' : 'errored';
    } else if (processing) {
      status = 'processing';
    } else if (processed) {
      status = 'processed';
    }

    const hideForm = this.hideErrorOnForm(processing);
    return (
      <div className={overlayStyle} onClick={this.onClickOverlay}>
        <div className={rootStyle}>
          <div
            className={styles.header}
            ref={(c) => {
              this.popupHeader = c;
            }}
          >
            <div className={styles.headerContent}>
              <span className={styles.name}>{this.title()}</span>
              {isNumber(typeof this.value === 'function' && this.value()) && (
                <Counter
                  endValue={this.value()}
                  duration={400}
                  className={styles.value}
                  formatValue={this.formatValue}
                />
              )}
            </div>
            <i
              className={`sl-custom-cross-1 ${styles.closeIcon}`}
              onClick={this.closePopup}
            />
          </div>
          <div className={styles.popupBody}>
            {updating && showStatus && hideForm ? (
              <PopupStatus
                status={status}
                errors={errors}
                actionableError={{
                  ...actionableError,
                  confirmAction: this.onActionableErrorConfirm.bind(this),
                  cancelAction: (
                    onActionableErrorCancel || this.closePopup
                  ).bind(this),
                }}
              />
            ) : (
              this.renderBody()
            )}
          </div>
          {this.renderDescription(styles.descriptionWrapper)}
        </div>
      </div>
    );
  }
}

export default Popup;
